.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    padding: 85px 0 15px 0;
    width: 275px;
    height: 100%;
    overflow-y: auto;
    background-color: var(--color-f2);
    z-index: 999;
}
.sidebar::-webkit-scrollbar {
    width: 12px;
}
.sidebar::-webkit-scrollbar-track {
    background: var(--color-0f0);
}
.sidebar::-webkit-scrollbar-thumb {
    background-color: var(--color-dcd);
    border-radius: 20px;
    border: 3px solid var(--color-0f0);
}
.sidebar a:hover, .sidebar a:focus {
    outline: none;
    text-decoration: none;
}
.sidebar_item_container {
    padding: 15px 20px 15px 30px;
    border-bottom: solid 1px var(--border-4e4);
}
.sidebar_item_container .icon {
    display: table-cell;
    font-size: 20px;
    color: var(--color-38c);
    width: 25px;
    line-height: 0;
    text-align: center;
}
.sidebar_item_container p {
    display: table-cell;
    vertical-align: middle;
    font-weight: bold;
    font-size: 16px;
    line-height: normal;
    padding: 0 0 0 12px;
    color: var(--color-37);
}
.footer {
    padding: 10px 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    color: var(--color-c6c);
}
h4.sidebar-title {
    padding: 13px 0 5px 25px; 
    margin: 20px 0 5px 0; 
    color: var(--button-171);
}

.active_link{
    background-color: var(--button-171);
    display: block;
    border-radius: 0 25px 25px 0;
}

.sidebar div a {
    text-decoration: none;
}

.active_link svg,
.active_link svg path,
.active_link p {
    fill: #fff !important;
    color: white !important;
}