:root {
  --bg-theme: #169e9a;
  --inv-theme: #509ee3;
  --border-4da: #ced4da;
  --border-1b1: #b1b1b1;
  --border-0f7: #eef0f7;
  --border-4e4: #e4e4e4;
  --button-171: #3e5171;
  --shadow-df6: #ededf6;
  --color-666: #666666;
  --color-fdf: #dfdfdf;
  --color-777: #777777;
  --color-aaa: #aaaaaa;
  --color-eee: #eeeeee;
  --color-aba: #bababa;
  --color-9d8: #d7d9d8;
  --color-f2: #f2f2f2;
  --color-036: #339036;
  --color-37: #373737;
  --color-1C7: #7291c7;
  --color-057: #495057;
  --color-c6c: #6b6c6c;
  --color-1f1: #f1f1f1;
  --color-2d2: #d2d2d2;
  --color-7c7: #c7c7c7;
  --color-4c4: #c4c4c4;
  --color-575: #757575;
  --color-d5d: #5d5d5d;
  --color-8fb: #f8f8fb;
  --color-cbe: #a6acbe;
  --color-187: #32b187;
  --color-800: #ffb800;
  --color-b38: #c57b38;
  --color-c61: #ff4c61;
  --color-c5c: #d34c5c;
  --color-571: #3e4571;
  --color-2a3: #3c62a3;
  --color-0f0: #f0f0f0;
  --color-dcd: #cdcdcd;
  --color-38c: #7d838c;
  --color-8ff: #f3f8ff;
  --color-cfb: #effcfb;
  --color-6cf: #21d6cf;
  --color-5f5: #f5f5f5;
  --color-1e5: #dfe1e5;
  --color-dbd: #bdbdbd;
  --color-6f8: #f5f6f8;
  --color-0e7: #dde0e7;
  --color-858: #223858;
  --color-5ea: #e2e5ea;
  --darkgreen: darkgreen;
  --color-666: #666666;
  --color-fdf: #dfdfdf;
  --color-777: #777777;
  --color-aba: #bababa;
  --color-9d8: #d7d9d8;
  --color-f2: #f2f2f2;
  --color-036: #339036;
  --color-37: #373737;
  --color-1C7: #7291c7;
  --color-057: #495057;
  --color-c6c: #6b6c6c;
  --color-1f1: #f1f1f1;
  --color-2d2: #d2d2d2;
  --color-7c7: #c7c7c7;
  --color-4c4: #c4c4c4;
  --color-575: #757575;
  --color-d5d: #5d5d5d;
  --color-8fb: #f8f8fb;
  --color-cbe: #a6acbe;
  --color-187: #32b187;
  --color-800: #ffb800;
  --color-b38: #c57b38;
  --color-c61: #ff4c61;
  --color-c5c: #d34c5c;
  --color-571: #3e4571;
  --color-2a3: #3c62a3;
  --color-0f0: #f0f0f0;
  --color-dcd: #cdcdcd;
  --color-38c: #7d838c;
  --color-8ff: #f3f8ff;
  --color-cfb: #effcfb;
  --color-6cf: #21d6cf;
  --color-5f5: #f5f5f5;
  --color-1e5: #dfe1e5;
  --color-dbd: #bdbdbd;
  --color-6f8: #f5f6f8;
  --color-0e7: #dde0e7;
  --color-858: #223858;
  --filter-color: #0071bc;
  --bg-theme-light-100: #eef4fd;
  --bg-theme-light-300: #ebeef3;
  --color-5ea: #e2e5ea;
  --font: 'roboto';
  --black: #000000;
  --red: #ff0000;
  --radius100: 100px;
}

body {
  background-color: var(--white);
}

.App {
  background-color: var(--white);
}

a {
  color: var(--button-171);
}

.theme-color {
  color: var(--button-171);
}

.rounded-pill {
  border-radius: var(--radius100);
}

.btn.theme-color {
  background: var(--button-171);
}

.head_bg {
  margin-top: -1.5rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.userProfile label {
  color: var(--color57);
}

.userProfile p.fs-5 {
  font-weight: 600;
  color: var(--color37);
}

.body-container {
  border: 1px solid var(--white);
  border-radius: 40px 40px 0px 0px;
  margin-left: 275px;
  margin-top: 84px;
  padding-bottom: 100px;
  height: 100%;
  background-color: var(--white);
}

.invoice-wrap {
  padding: 30px 40px 60px 40px;
  background-color: var(--white);
  border-radius: 40px 40px 0px 0px;
}

button[disabled] {
  background-color: var(--gray);
  color: var(--color-666);
}

button[disabled]:hover,
button[disabled]:focus {
  background-color: var(--gray);
  color: var(--color-666);
  cursor: auto;
  opacity: 1;
  outline: none;
}

.fw-500 {
  font-weight: 500;
}

.danger {
  color: var(--danger);
}

.view-item ul li {
  overflow: hidden;
}

.invoice-wrap .form-control {
  height: 48px;
}

textarea.form-control {
  height: auto !important;
}

.invoice-wrap .custom-file-label {
  height: 48px;
  line-height: 32px;
}

.invoice-wrap .custom-file-label:after {
  height: 46px;
  line-height: 33px;
}

.invoice-wrap select {
  background-position: 95% 50%;
  background-size: 13px;
}

.add-invoice-btn,
.invoice-meta-link {
  display: inline-block;
  min-width: 180px;
  padding: 14px 15px;
  background-color: var(--button-171);
  color: var(--white);
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  border-radius: 4px;
  border: none;
  transition: all ease .4s;
}

.add-invoice-btn:hover,
.invoice-meta-link:hover {
  text-decoration: none;
  opacity: 0.85;
}

.add-invoice-btn-second,
.invoice-meta-link {
  display: inline-block;
  min-width: 180px;
  padding: 14px 15px;
  background-color: var(--button-171);
  color: var(--white);
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  border-radius: 4px;
  border: none;
  transition: all ease .4s;
}

.add-invoice-btn-second:hover,
.invoice-meta-link:hover {
  color: var(--white);
  text-decoration: none;
  opacity: 0.85;
}

.actions a,
.actions button {
  display: inline-block;
  color: var(--button-171);
  border: none;
  background-color: transparent;
  padding: 0;
}

.mt2 {
  max-width: 95px;
  height: auto !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
  color: var(--white) !important;
  border-color: var(--button-171) !important;
  background: var(--button-171) !important;
  font-weight: 500 !important;
  padding: 0.375rem 1rem !important;
  font-size: 15px !important;
  line-height: 1.5 !important;
  transition: all ease .4s !important;
  -webkit-transition: all ease .4s;
  border-radius: 100px !important;
}

.buttonbadge.newcolor,
.mt2.newcolor {
  background: var(--filter-color) !important;
  border-color: var(--filter-color) !important;
}

.buttonbadge {
  color: var(--white);
  background: var(--button-171);
  font-weight: 400;
  font-size: 13px;
  transition: all ease .4s;
  -webkit-transition: all ease .4s;
  padding: 4px 10px 6px 10px;
}

.mt2:hover {
  opacity: .85;
}

.invoice-meta {
  padding-top: 25px;
}

.select-box .add-invoice-btn {
  padding: 11px 15px;
}

.search-box .form-group {
  margin-bottom: 0;
}

.invoice-wrap .search-box .form-control {
  padding: 11px 15px;
}

.invoices-view {
  padding: 25px;
  border: 1px solid var(--color-fdf);
  border-radius: 5px;
}

.invoices-view .row {
  margin: 0 -30px;
}

.invoices-view [class*="col-"] {
  border-right: 1px solid var(--color-fdf);
  padding: 0 30px;
}

.invoices-view [class*="col-"]:last-child {
  border-right: none;
}

.view-item h4 {
  font-weight: 700;
  font-size: 20px;
}

.view-item ul {
  padding: 0;
  margin: 0;
  text-align: right;
  font-size: 18px;
  list-style: none;
}

.view-item ul li {
  padding: 13px 0;
  border-bottom: 1px solid var(--color-fdf);
  overflow: hidden;
}

.view-item ul li:last-child {
  border-bottom: none;
}

.view-item ul li span {
  float: left;
  color: var(--color-777);
  text-align: left;
}

.modalRight {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  height: 100%;
  left: 100% !important;
  transition: all .5s ease-in-out;
  -webkit-transition: all .5s ease-in-out !important;
  background: var(--white);
  box-shadow: -4px 0px 6px rgba(0, 0, 0, 0.06);
  border-radius: 0px 0px 0px 0px;
  max-width: 500px;
}

.modalRight .modal-content {
  height: 100%;
  border: none;
  border-radius: 0;
  background: transparent;
}

.modal-open .modal {
  margin-right: 0 !important;
  padding-right: 0 !important;
}

.modal-open .modalRight {
  left: 0 !important;
}

.modalRight .modal-body {
  padding: 1.5rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.modalRight .modal-header {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
}

.sc-bwzfXH {
  background: #fff
}

.closebtn {
  cursor: pointer;
}

.cursor {
  cursor: pointer !important;
}

/* Loader CSS */

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
}

.loader .spinner-border {
  width: 70px;
  height: 70px;
  border-right-color: var(--button-171);
}

/* Notification CSS */

.notification-wrap .card {
  margin-bottom: 25px;
  border: none;
  background-color: var(--white);
}

.notification-wrap .card-header {
  padding: 0;
  cursor: pointer;
  border-bottom: none;
  background-color: var(--white);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.notification-wrap .card-body {
  padding: 0;
}

.notification-wrap .card-body p {
  margin-bottom: 0;
}

.notification-item h5 {
  color: var(--danger);
  font-weight: 500;
  font-size: 16px;
}

.arrow-down {
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-left: 10px;
  position: relative;
  top: -3px;
  border-style: solid;
  border-color: var(--bg-theme);
  border-width: 0px 1px 1px 0px;
  transform: rotate(45deg);
  transition: border-width 150ms ease-in-out;
}

.notification-item h6 {
  font-weight: 500;
  color: var(--black);
}

.notification-info {
  display: none;
}

.notification-title {
  color: var(--black);
}

/*New CSS Add 10-08-2021*/
.search-invoice {
  border: 1px solid var(--color-aba);
  border-radius: 4px;
  background-color: var(--white);
  font-size: 18px;
  height: 48px;
  color: var(--button-171);
  padding-left: 15px;
  font-weight: 700;
}

.search-box {
  margin-top: 15px;
}

.select-box .form-group {
  margin-bottom: 0;
}

.invoice-meta .invoice-meta-link {
  color: var(--white);
}

.search-box .jZkBQk>.wrapper,
.select-box .jZkBQk>.wrapper {
  border-radius: 4px;
}

.search-box .jZkBQk>.wrapper:hover,
.select-box .jZkBQk>.wrapper:hover,
.search-box .jZkBQk>.wrapper:active,
.select-box .jZkBQk>.wrapper:active,
.search-box .jZkBQk>.wrapper:focus,
.select-box .jZkBQk>.wrapper:focus {
  box-shadow: none;
}

.sidebar-title {
  font-weight: bold;
  padding: 18px 10px;
  font-size: 22px;
}

.hIGOnQ {
  z-index: 9;
}

table.table {
  white-space: nowrap;
  text-align: left;
  font-weight: 400;
}

table.table.txInherit {
  white-space: inherit;
}

.perra_text {
  word-break: break-all;
  white-space: break-spaces;
}

.search-box.mt-0 .hIGOnQ {
  margin-bottom: 0;
}

.invoice-wrap h2 {
  font-size: 40px;
  margin-bottom: 1rem;
  font-family: var(--font);
  font-weight: normal;
  text-align: left;
}

.model_table {
  white-space: normal;
}

.model_table td {
  padding: 10px 0;
  border-bottom: solid 1px var(--color-9d8) !important;
}

.model_table td b {
  display: block;
}

.model_table td p {
  margin: 0;
  display: block !important;
}

/*pagination*/

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
  justify-content: center;
}

.pagination>li {
  padding: 0 5px;
}

.pagination>.active>a {
  background-color: var(--button-171);
  border-color: var(--button-171);
  color: var(--white);
}

.pagination>li>a {
  outline: none;
  cursor: pointer;
  display: inline-block;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  line-height: 32px;
  text-align: center;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
  background-color: var(--button-171);
  border-color: var(--button-171);
  outline: none;
  color: var(--white);
}

.pagination>li>a,
.pagination>li>span {
  color: var(--button-171);
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
  border-radius: unset
}

button:hover,
button:focus {
  box-shadow: none;
  outline: none;
}

#done_btn {
  width: 207px;
  height: 54px;
  border: none;
  border-radius: 8px;
  font-family: var(--font);
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 21px;
  text-align: center;
  color: var(--color-f2);
}

#save_n_exit {
  width: 150px;
  height: 54px;
  border: 0px;
  background-color: var(--white);
  text-align: center;

  font-family: var(--font);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  align-items: center;
  color: var(--button-171);
}

.invoice-suggestion .add-invoice-btn {
  background: var(--color-036);
  padding: 0;
  min-width: auto;
  font-size: 18px;
  position: absolute;
  right: 0;
  top: -30px;
  width: 20px;
  height: 20px;
  line-height: 1.2;
}

.marchant_filter_modal .react-date-picker {
  width: 100%;
}

.marchant_filter_modal .react-date-picker .react-date-picker__wrapper {
  height: 48px;
  border-radius: 4px;
  border-color: var(--border-4da);
}

button.btn-small {
  min-width: auto;
  padding: 12px 15px 15px 15px;
  font-size: 16px;
  line-height: 1;
}

.radio_btn input {
  margin-left: 0 !important;
  position: static;
}

.fs-2 {
  font-family: var(--font);
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  margin-bottom: 0;
  color: var(--black);
}

.inputSize {
  background-color: var(--white);
  border: 1px solid var(--color-9d8);
  box-sizing: border-box;
  border-radius: 8px;
  height: 54px;
}

.pendBox input.form-control {
  height: 45px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.htIrame {
  position: relative;
}

.perra_text,
.text_break {
  word-break: break-all;
  white-space: break-spaces;
}

.backToBuyerPlatform {
  font-family: var(--font);
  font-size: 16px;
  line-height: 21px;
  align-items: center;
}

.table-responsive {
  table-layout: fixed;
  display: table;
}

.filter {
  font-family: var(--font);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 40px;
  color: var(--color-1c7);
  text-align: center;
  display: inline-block;
  border-radius: 5px;
  width: 80px;
  height: 40px;
  border: 1px solid var(--color-2d2);
}

.nav-tabs .nav-link,
.active {
  color: var(--button-171);
}

.SearchBar {
  width: 210px;
  height: 40px;
  border: 1px solid var(--color-2d2);
  box-sizing: border-box;
  border-radius: 5px;
  padding-left: 10px;
}

.SearchBar::placeholder {
  color: var(--button-171);
}

.sortable tr {
  cursor: pointer;
}

.modalRight .modal-content {
  margin-top: 70px;
}

.languageDD {
  display: flex;
  align-items: center;
  padding: 0 0 15px 0;
}

.languageDD .PhoneInputCountry {
  position: relative;
}

.languageDD .PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 75px;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}

.languageDD .PhoneInputCountryIcon {
  height: 52px;
  border: 1px solid var(--border-4da);
  width: 75px;
  padding: 14px 20px;
  border-radius: 8px;
  margin-right: 5px;
}

.languageDD .PhoneInputInput {
  padding: 0 20px;
  height: 52px;
  line-height: 5;
  background: var(--white);
  border: 1px solid var(--border-4da);
  border-radius: 8px;
  width: 100%;
}

.languageDD .PhoneInputInput:focus {
  outline: none;
  background: var(--white);
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--white) inset !important
}

.pad-80 {
  padding: 80px;
}

.gl-bg {
  width: 100%;
  height: 100%;
}

.gl-opacity {
  width: 100%;
  height: 100%;
}

.gl-opacity::before {
  background-color: var(--button-171);
  opacity: 0.95;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.lg-loginBox {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  background: var(--white);
  color: var(--white);
  overflow: hidden;

  padding: 50px 100px 30px 55px;
  border-radius: 40px 40px 0 0;
}

.lg-loginBox input {
  border-radius: 7px !important;
}

.lg-loginBox input[type="text"],
.lg-loginBox input[type="password"] {
  margin-bottom: 15px;
  border-radius: 2px;
  height: 52px;
  box-shadow: none !important;
  background: transparent;
  color: var(--black);
  border: solid 2px #dfe7f5 !important;
}

.lg-loginBox input::-webkit-input-placeholder {
  color: var(--white);
}

.lg-loginBox input::-moz-placeholder {
  color: var(--white);
}

.lg-loginBox input:-ms-input-placeholder {
  color: var(--white);
}

.lg-loginBox input:-moz-placeholder {
  color: var(--white);
}

.lg-loginBox input[type="submit"] {
  border-radius: 4px;
  font-size: 15px;
  font-weight: 500;
  background: var(--color-858);
  line-height: 36px;
}

.lg-loginBox input[type="submit"]:hover {
  opacity: .7;
}

.lg-loginBox input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.lg-loginBox .lg-title {
  font-size: 28px;
  font-weight: 600;
  line-height: 30px;
  padding-bottom: 20px;
  letter-spacing: -1px;
  color: var(--button-171);
}

.flIcon {
  position: absolute;
  right: -50px;
  top: -80px;
  font-size: 170px;
  opacity: .05;
}

.fontsize {
  font-size: 14px;
  font-weight: 600;
}


.smallLine {
  font-size: 14px;
  line-height: 18px;
  padding-top: 5px;
  letter-spacing: 0;
  color: #ebebeb;
}

.colData {
  padding-left: 30px;
  max-width: 400px;
}

.square {
  width: 70px;
  min-width: 70px;
  height: 70px;
  border: 2px solid #a7b2c7;
  border-radius: 100%;
  text-align: center;
  line-height: 58px;
}

.ntf {
  margin-top: 1.5vh;
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  color: #767676;
}

.fontsize18 {
  font-size: 18px;
  font-weight: 600;
}

.size14 {
  font-size: 14px;
}

.size10 {
  font-size: 10px;
}

.opacity25 {
  opacity: .70;
}

.overflow_auto {
  overflow: overlay;
}

.acc_tx {
  font-size: 16px;
  font-weight: 700;
  color: var(--button-171);
  display: inline-block;
}

.action_msg {
  z-index: 9999;
  overflow: hidden !important;
}

.action_msg .overflow-auto.modal-body {
  padding-bottom: 200px;
}

.action_msg .pr-3.notifiBox {
  overflow-x: hidden !important;
}

.action_msg .mdlDir {
  margin-top: 72px;
  margin-bottom: 0;
  margin-right: 0;
}

.action_msg .modal-content {
  border: 0;
  border-radius: 30px 0 0 0;
}

.action_msg .modal-content .modal-header {
  font-size: 26px;
  font-family: var(--font);
  font-weight: 500;
  margin-bottom: 1rem;
  padding: 2rem 2rem 1rem 2rem;
}

.action_msg .modal-header .close span {
  font-weight: 300;
  font-size: 36px;
}

.notifiBox div {
  display: flex;
  display: -webkit-box;
  font-weight: 700;
}

.notifiBox p {
  margin-bottom: 20px;
  color: #000;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  padding-left: 15px;
}

.clearBox {
  clear: both;
}

.col_left {
  padding-right: 40px;
  flex-basis: 100%;
}

.col_left .btn_light {
  background: #e2e6ea;
  font-weight: 600;
}

.filterButton:focus {
  outline: none !important;
  box-shadow: none !important;
}

.col_right {
  position: absolute;
}

.col_right .filterButton {
  font-size: 32px;
  line-height: 1.2;
  padding: 0;
}

.col_right .filterButton span {
  line-height: 8px;
  position: relative;
  top: -8px;
  width: 35px;
}

.col_right .invoiceBox {
  position: absolute;
  right: 0;
  top: 0;
  top: 45px;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 0.25rem;
  min-width: 10rem;
  background: #fff;
  box-shadow: 0 7px 10px #e2e2e2;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addInvoice_icn svg,
.importInvoice_icn svg,
.exportInvoice_icn svg {
  width: 30px;
  height: 30px;
  fill: var(--button-171);
  color: var(--button-171);
}

.addInvoice_icn,
.exportInvoice_icn {
  padding: 0.375rem 0.75rem
}

.importInvoice_icn button,
.exportInvoice_icn button,
.addInvoice_icn button {
  padding: 0;
}

.lightcolor {
  background: var(--color-5ea);
}

.contFluid td {
  padding: 3px 10px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  border: none;
}

td.fw-medium {
  font-size: 16px;
  font-weight: 500;
}

.pagination {
  display: block;
  text-align: center;
  width: 50%;
  margin: 0 auto;
  padding-top: 35px;
}

.pagination li {
  display: inline-block;
}

.pagination>li>a {
  line-height: 30px;
}

.pagination .next {
  float: right;
  white-space: nowrap;
}

.pagination .previous {
  float: left;
  white-space: nowrap;
}

/*29june*/
.search-box .frjscc {
  min-height: 40px;
}

.frjscc>input {
  padding-left: 5px !important;
}

.ViWoI {
  margin-top: 0 !important;
}

.hIGOnQ>.wrapper {
  border: 1px solid var(--border-4da) !important;  
}

.search-box .hIGOnQ>.wrapper,
.search-box .hIGOnQ>.wrapper:hover {
  box-shadow: none !important;
}

.search-box .search-invoice,
.search-box .form-control {
  border-radius: 100px;
  font-size: 15px;
  font-weight: 400;
  height: 40px !important;
  padding: 0 10px !important;
  border-color: var(--border-4da) !important;
}

.search-box .col-auto,
.search-box .col {
  padding-left: 10px;
  padding-right: 10px;
}

select.form-control {
  appearance: none;
  -webkit-appearance: none;
  background: url("/downArrow.png") no-repeat 94% center / 10px !important;
  /* background: url("/downArrow.png") no-repeat 94% center / 10px !important; */
}

select.form-control:disabled {
  background: #e9ecef !important;
}

.search-box input[type="date"].form-control,
.search-box input[type="datetime-local"].form-control,
.search-box .react-datepicker__input-container .form-control {
  appearance: none;
  -webkit-appearance: none;
  background: url("assests/calendarIcon.svg") no-repeat 94% center / 20px;
}

.search-box input[type="date"]::-webkit-inner-spin-button,
.search-box input[type="datetime-local"]::-webkit-calendar-picker-indicator,
.search-box input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.search-invoice::-webkit-input-placeholder,
.search-box .form-control::-webkit-input-placeholder {
  color: var(--color-057);
  opacity: 1
}

.search-invoice::-moz-placeholder,
.search-box .form-control::-moz-placeholder {
  color: var(--color-057);
  opacity: 1
}

.search-invoice:-ms-input-placeholder,
.search-box .form-control:-ms-input-placeholder {
  color: var(--color-057);
  opacity: 1
}

.search-invoice:-moz-placeholder,
.search-box .form-control:-moz-placeholder {
  color: var(--color-057);
  opacity: 1
}

.white-space-nowrap {
  white-space: nowrap
}

.innerAllEliment div.wrapper {
  height: 48px !important;
  border-radius: 4px !important;
  margin-top: 32px;
  box-shadow: none !important
}

textarea {
  border: 1px solid var(--border-4da);
  border-radius: 4px
}

.text {
  font-family: var(--font);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--color-37);
}

@media (min-width:1024px) and (max-width:1299px) {
  .search-box .col {
    max-width: 100%;
    margin-bottom: 15px;
    min-width: 30%
  }
}

.docFieldHeight {
  height: 48px;
  outline: none !important;
}

.docFieldLeft {
  border-radius: 8px 0px 0px 8px !important;
  cursor: pointer
}

.docField {
  border-left: 0 !important;
  text-align: center;
  border-radius: 0px 8px 8px 0px !important
}

.docField.position-relative [type="file"] {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
  opacity: 0;
  cursor: pointer
}

.outline-none {
  outline: none !important;
}

.addiDoc svg.mt-3,
.addiDoc button.bg-transparent.mt-2 {
  margin-top: 0 !important;
}

.viewMcnt .viewmrc .input-group .col-sm-11 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.viewMcnt .viewmrc .input-group .col-sm-1 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.viewMcnt .viewmrc[data-icon="calendar-alt"],
.viewMcnt .viewmrc .input-group .col-sm-1 .input-group.mb-3 svg {
  margin-top: 15px !important;
}

.viewMcnt .viewmrc .viewmrc .col-sm-1 button {
  padding-top: 0 !important;
}

.textapText .form-check input {
  width: 17px;
  height: 17px;
}

.textapText strong {
  font-weight: 500;
  color: #484747;
}

.reqApp .modal-header {
  background: var(--button-171);
}

.reqApp .close {
  color: var(--white);
  opacity: 1;
}

.reqApp .close span {
  font-size: 40px;
}

/* .reqApp table th,
.reqApp table td {
  padding-left: 0;
  padding-right: 0;
} */
.adminCheck table th:last-child,
.adminCheck table td:last-child {
  width: 200px;
}

.reqApp.adminCheck {
  max-width: 1000px !important;
}

.reqApp.adminCheck .form-check-input {
  width: 20px;
  height: 20px;
  accent-color: rgb(48, 168, 48);
}

.monitoringloader {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: none;
}

/* .monitoringloader::before {
  content: '';
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: .5;
  z-index: 999;
} */

.monitoringloader .spinner-border {
  top: 45%;
  position: absolute;
}

.accountAutoSearch .wrapper,
.accountAutoSearch .hIGOnQ {
  position: static !important;
}

.accountAutoSearch .bWZouD {
  box-shadow: 0 0 10px rgb(228, 227, 227) !important;
  z-index: 999;
}

.bWZouD li {
  border-radius: 15px !important;  
}

.rightpaid th {
  font-weight: 600;
  color: #6e6e6e;
}

.rightpaid h5 {
  margin: 0;
  font-weight: 600;
  color: var(--black);
  font-size: 16px;
}

.textWrapNormal {
  white-space: normal !important;
}


.model-loader {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.recovery-calder input[name="recoveryDate"] {border:none}
.recovery-calder input[name="recoveryDate"]:focus{box-shadow: none;}
.model-loader .spinner-border {
  width: 70px;
  height: 70px;
  border-right-color: var(--button-171);
}

/*---------------------
Document section css
---------------------*/

.docs-box {
  border: 1px solid #aeaeae;
  border-radius: 4px;
  padding: 15px 15px;
  /* margin-bottom: 20px; */
}

.docs-box:last-child {
  margin-bottom: 0;
}

.docs-box-head {
  padding-bottom: 15px;
  position: relative;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #aeaeae;
  margin-bottom: 10px;
}

.docs-box-head:nth-last-child(2) {
  border: 0;
  margin-bottom: 0;
}

.docs-box h4 {
  font-size: 20px;
  margin: 0;
}

.action-btn {
  display: inline-block;
}

.action-btn button {
  all: unset;
  width: 36px;
  height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #ddd;
  margin-right: 8px;
}

.action-btn button:last-child {
  margin-right: 0;
}

/* .docs-box .action-btn{
  position: absolute;
  right: 0;
  top: 0;
} */

.docs-box-body p {
  font-size: 16px;
  line-height: 24px;
  color: #222;
}

.upload-btn {
  position: relative;
}

.upload-btn input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}

.flex-1 {
  flex: 1;
}

.MuiAutocomplete-hasClearIcon,
.css-1wuilmg-MuiAutocomplete-root {
  width: 100% !important;
  height: 48px;
}

.css-1wuilmg-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  top: -4px !important;
}
.swal2-close:hover {
  color: var(--button-171) !important;
}
.swal2-close:focus {
  box-shadow: none !important;
}

.tapheading {
  background: var(--button-171);
  color: var(--bs-white);
  padding: 10px 0 14px 10px !important;
}

.chHead {
  background-color: var(--color-f2);
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
}

.chkSize {
  line-height: 47.333px;
}
.chkSize input{
  width: 16px;
  height: 16px;  
}

.adminHead {
  font-size: 15px;
  font-weight: 500;
}
.adminHead svg {
  width: 20px !important;
}
.tabForm {
  border-bottom-width: 2px;
}
.tabForm .nav-link {
  border: none;
}
.tabForm .nav-link.active {
  background: var(--button-171);
  color: var(--white); 
}


.democlass input[type=number]::-webkit-inner-spin-button, 
.democlass input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.disabledCursor {
  pointer-events: auto ! important;
  cursor: not-allowed ! important;
  padding-top: 11px !important;
  padding-bottom: 10px !important;
}

.largeFnt{
  font-size: 50px;
  -webkit-text-stroke-width: .8px;
  color: #10182f;
  margin: 30px 0 15px 0;
}
.unauthBox {
  text-align: center;
}
.unauthBox p {
  font-size: 20px;
  color: #3b516f;
  margin: 0;
  line-height: 1.2;
}
.unauthBox p a {
  color: #ee2e2e;
  font-weight: 600;
}
.gobacklink {
  font-size: 18px;
  color: #3b516f;
  text-decoration: underline;
  text-transform: uppercase;
  margin-top: 35px;
  font-weight: 600;
}

.opacity-75 {
  opacity: .65 !important;
}

.table-responsive_new {
  width: 100%;
  overflow: auto;
}

.blankTextWrap {
    width: auto;
}

.fillTextWrap {
  display: inline-block;
  text-wrap: wrap;
  width: 600px;
}

.fillTextWrap.bankpg {
  width: 400px;
  overflow-wrap: break-word;
}

.trackXVisibleWrap div {
  position: static !important;
}
.trackXVisibleWrap .ScrollbarsCustom-Track {
  height: 4px !important;
}
.trackXVisibleWrap .ScrollbarsCustom-Track div {
  background: #bdc1c9 !important;
}



